.contact-card {
  background-color: #f7f7f7;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); 
  border: 2px solid #007bff;
}

.contact-card h4 {
  color: #007bff;
  font-size: 22px;
  margin-bottom: 10px;
}

.contact-card p {
  margin: 0;
  font-size: 16px;
}

@media (max-width: 768px) {
  .contact-card {
      padding: 15px; 
  }

  .contact-card h4 {
      font-size: 20px; 

  .contact-card p {
      font-size: 15px; 
  }
}

@media (max-width: 480px) {
  .contact-card {
      padding: 10px; 
  }

  .contact-card h4 {
      font-size: 18px; 
  }

  .contact-card p {
      font-size: 14px; 
  }
}
}
