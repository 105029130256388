.footer {
  width: 100%; 
  position: relative; 
  text-align: center;
  margin-top: 30px; 
  padding: 20px 0; 
  background-color: #c4d4d4;
  bottom: 0; 
}

.footer p {
  font-size: 14px;
  color: #777;
  margin: 0; 
}

@media (max-width: 768px) {
  .footer p {
    font-size: 12px; 
  }
  
  .footer {
    padding: 15px 0; 
  }
}

@media (max-width: 480px) {
  .footer p {
    font-size: 10px; 
  }

  .footer {
    padding: 10px 0; 
  }
}
