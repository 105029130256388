.professional-experience {
  background-color: #c4d4d4;
  margin-bottom: 30px;
  padding: 20px; 
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  border: 1px solid #e0e0e0;
  text-align: justify;
  line-height: 1.5;
  overflow: hidden; 
}

.professional-experience:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.experience-item {
  background-color: white; 
  padding: 15px; 
  margin-bottom: 15px; 
  border-radius: 8px; 
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); 
  transition: box-shadow 0.3s ease; 
  
}

.experience-item:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); 
}

.professional-experience h4 {
  color: darkslategrey;
  font-size: 20px;
  margin: 0 0 5px 0; 
  padding:5px;
 
}

.professional-experience span {
  padding:5px;
  font-weight: 500;
}


.professional-experience h3 {
  margin: 15px auto 10px auto;
  color: #333;
  font-size: 24px;
  text-align: left;
  padding-left: 10px; 
}


.professional-experience p {
  margin-top: 0;
  overflow: auto;
 
}

.professional-experience ul {
  list-style: none;
  line-height: 1.8;
  padding: 5px;
}





.company-icon {
  width: 20px; 
  height: 20px; 
  margin-right: 10px; 
  vertical-align: middle;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .professional-experience {
    padding: 15px; 
  }

  .professional-experience h4 {
    font-size: 18px;
  }

  .professional-experience p {
    font-size: 14px; 
    margin-top: 0; /* Ajustando a margem superior */
  }

  .experience-item {
    padding: 10px; 
  }

  .toggle-button {
    padding: 8px 12px; 
  }
}

@media (max-width: 480px) {
  .professional-experience {
    padding: 10px; 
  }

  .professional-experience h4 {
    font-size: 16px; 
  }

  .professional-experience p {
    font-size: 12px; 
  }

  .experience-item {
    padding: 8px; 
  }

  .toggle-button {
    padding: 6px 10px; 
  }
}
