.language-card {
  background-color: #c4d4d4;
  padding: 20px; 
  border-radius: 10px; 
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
  margin-bottom: 20px; 
}

.language-card h3 {
  margin-bottom: 10px; 
  color: #333; 
  font-size: 24px; 
}

.language-card p {
  font-size: 16px; 
  color: darkslategrey; 
}


@media (max-width: 768px) {
  .language-card {
    padding: 15px;
  }

  .language-card h3 {
    font-size: 20px; 
  }

  .language-card p {
    font-size: 14px; 
  }
}
