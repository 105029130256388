header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  border-bottom: 2px solid #e0e0e0;
  padding: 20px 20px 20px 40px;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
}

.container {
  margin: 200px auto 40px auto; 
  padding: 30px;
}

.toggle-button {
  background-color: #356ea7;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  height: 50px;
}


.toggle-button:hover {
  background-color: #104680;
}


@media (max-width: 768px) {
  .container {
    width: 90%; 
    
    margin-top:20px; 
    margin-right: auto;
    margin-bottom: 40px; 
    margin-left: auto;
    padding: 20px; 
  }
}

@media (max-width: 380px) {
  header {
    flex-direction: column; 
    align-items: center; 
    padding: 15px; 
  }

  .container {
    margin-top: 80px; /* Ajuste para telas menores */
  }
}
